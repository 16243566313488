import { DateRange, ReportListRequestBase, ReportListResponseBase } from './report';

export interface ProductRecentReport {
  id: number;
  sku: string;
  name: string | null;
  priceExVat: number | null;
  vatPercent: number | null;
  vatCode: string | null;
  unit: string | null;
  isDeleted: boolean;
  status: ProductSyncReportStatus;
  statusMessage: string | null;
  dateUtc: string;
  inventory: number | null;
}

export enum ProductSyncReportStatus {
  QUEUED = 'Queued',
  SUCCESS = 'Success',
  WARNING = 'Warning',
  FAILURE = 'Failure',
  TIMEOUT = 'Timeout',
}

export interface ProductRecentReportListRequest extends ReportListRequestBase, DateRange {
  searchKey: string | null;
}

export interface ProductRecentReportListResponse extends ReportListResponseBase {
  productReports: ProductRecentReport[];
}

export interface ProductOperationReport {
  sku: string;
  name: string | null;
  dateUtc: string;
  priceExVat: number | null;
  vatPercent: number | null;
  vatCode: string | null;
  unit: string | null;
  inventory: number | null;
  reason: string | null;
  operations: Operation[];
}

export interface Operation {
  id: number;
  originSystemType: string;
  originSystemId: string;
  targetSystemType: string;
  targetSystemId: string;
  status: ProductSyncReportStatus;
}

export interface ProductOperationReportListRequest extends ReportListRequestBase, DateRange {
  searchKey: string | null;
  sku: string;
}

export interface ProductOperationReportListResponse extends ReportListResponseBase {
  productOperationReports: ProductOperationReport[];
}
