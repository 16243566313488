<router-outlet></router-outlet>

<ng-container *ngIf="nonBlockingLoaderService.loading$ | async">
  <umw-progress type="linear" [@fadeInOnEnter] [@fadeOutOnLeave]></umw-progress>
</ng-container>

<ng-container *ngIf="blockingLoaderService.loading$ | async">
  <aeb-backdrop class="blocking-loader-backdrop" [@fadeInOnEnter] [@fadeOutOnLeave]>
    <umw-progress type="circular"></umw-progress>
  </aeb-backdrop>
</ng-container>

<ng-container *ngIf="loggingOutBlockingLoaderService.loading$ | async">
  <aeb-backdrop class="logging-out-blocking-loader-backdrop" [@fadeInOnEnter] [@fadeOutOnLeave]>
    <div class="logging-out-blocking-loader-content">
      <picture>
        <img class="img-fluid" src="/assets/logo.svg" width="354" height="50" [attr.alt]="appTitle">
      </picture>

      <umw-progress type="circular" class="logging-out-blocking-loader-progress"></umw-progress>

      <div class="logging-out-blocking-loader-text text-muted" translate>Components.App.LoggingOut</div>
    </div>
  </aeb-backdrop>
</ng-container>
