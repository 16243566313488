import {
  DateRangeOption,
  ProductOperationReportListResponse,
  ProductRecentReportListResponse,
} from '@Libs/model';
import { RecentReportListOptions, OperationReportListOptions, INITIAL_LOGS_AMOUNT } from '@WebUi/product-sync/models/product-sync.model';

export interface ProductSyncStateModel {
  recentReports: ProductRecentReportListResponse | null;
  recentReportsOptions: RecentReportListOptions;
  operationReports: ProductOperationReportListResponse | null;
  operationReportsOptions: OperationReportListOptions;
}

export const PRODUCT_SYNC_STATE_DEFAULTS: ProductSyncStateModel = {
  recentReports: null,
  recentReportsOptions: {
    searchKey: null,
    dateRangeOption: DateRangeOption.CUSTOM_DATE_RANGE,
    dateRangeStart: null,
    dateRangeEnd: null,
    offset: 0,
    pageSize: INITIAL_LOGS_AMOUNT,
  },
  operationReports: null,
  operationReportsOptions: {
    searchKey: null,
    sku: '',
    dateRangeOption: DateRangeOption.CUSTOM_DATE_RANGE,
    dateRangeStart: null,
    dateRangeEnd: null,
    offset: 0,
    pageSize: INITIAL_LOGS_AMOUNT,
  },
};
