import { DateRangeOption } from '@Libs/model';
import {
  DEFAULT_INVOICE_REPORTS_TABLE_CONTEXT,
  InvoiceReportsOptions,
} from '@WebUi/invoices/models/invoices.model';

export type InvoicesStateModel = InvoiceReportsOptions;

export const INVOICES_STATE_DEFAULTS: InvoicesStateModel = {
  ...DEFAULT_INVOICE_REPORTS_TABLE_CONTEXT,
  filter: {
    dateRangeOption: DateRangeOption.CUSTOM_DATE_RANGE,
    dateRangeStart: null,
    dateRangeEnd: null,
    salesChannels: [],
    searchKey: '',
    status: [],
  },
};
