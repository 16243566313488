export enum SortDirection {
  ASC = 0,
  DESC = 1,
}

export const SORT_DIRECTIONS: SortDirection[] = [
  SortDirection.ASC,
  SortDirection.DESC,
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isSortDirection(sortDirectionType: any): sortDirectionType is SortDirection {
  return SORT_DIRECTIONS.includes(sortDirectionType);
}

export enum DateRangeOption {
  TODAY = 'TODAY',
  LAST_WEEK = 'LAST_WEEK',
  LAST_MONTH = 'LAST_MONTH',
  LAST_THREE_MONTHS = 'LAST_THREE_MONTHS',
  LAST_YEAR = 'LAST_YEAR',
  CUSTOM_DATE_RANGE = 'CUSTOM_DATE_RANGE',
}

export const DATE_RANGE_OPTIONS: DateRangeOption[] = [
  DateRangeOption.TODAY,
  DateRangeOption.LAST_WEEK,
  DateRangeOption.LAST_MONTH,
  DateRangeOption.LAST_THREE_MONTHS,
  DateRangeOption.LAST_YEAR,
  DateRangeOption.CUSTOM_DATE_RANGE,
];

export interface DateRange {
  dateRangeStart: number | null;
  dateRangeEnd: number | null;
}

export interface DateRangeFilterOptions extends DateRange {
  dateRangeOption: DateRangeOption;
}

export function isDateRangeFilterOptionsModificated(options: DateRangeFilterOptions): boolean {
  return isPredefinedDateRangeOption(options.dateRangeOption) || (options.dateRangeStart !== null || options.dateRangeEnd !== null);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isDateRangeOption(dateRangeOption: any): dateRangeOption is DateRangeOption {
  return DATE_RANGE_OPTIONS.includes(dateRangeOption);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isPredefinedDateRangeOption(dateRangeOption: any): boolean {
  return isDateRangeOption(dateRangeOption) && dateRangeOption !== DateRangeOption.CUSTOM_DATE_RANGE;
}

export function dateRangeOptionToDateRange(dateRangeOption: DateRangeOption, startWith: Date = new Date()): {
  start: Date,
  end: Date,
} {
  if (dateRangeOption === DateRangeOption.CUSTOM_DATE_RANGE) {
    throw new Error('Can not convert custom date range option to date');
  }

  const result = new Date(startWith);

  switch (dateRangeOption) {
    case DateRangeOption.TODAY:
      break;
    case DateRangeOption.LAST_WEEK:
      result.setDate(result.getDate() - 7);
      break;
    case DateRangeOption.LAST_MONTH:
      result.setMonth(result.getMonth() - 1);
      break;
    case DateRangeOption.LAST_THREE_MONTHS:
      result.setMonth(result.getMonth() - 3);
      break;
    case DateRangeOption.LAST_YEAR:
      result.setMonth(result.getMonth() - 12);
      break;
  }

  return {
    start: result,
    end: startWith,
  };
}

export function convertPredefinedDateRangeOptionToStartDateInSeconds(dateRangeOption: DateRangeOption): number {
  const dateRange = dateRangeOptionToDateRange(dateRangeOption);

  dateRange.start.setUTCHours(0, 0, 0, 0);

  return Math.floor(dateRange.start.valueOf() / 1000);
}

export function decomposeDateRangeFilterOptions(dateRangeFilterOptions: DateRangeFilterOptions): DateRange {
  let dateRangeStart: number | null = null;
  let dateRangeEnd: number | null = null;

  if (dateRangeFilterOptions.dateRangeOption) {
    if (isPredefinedDateRangeOption(dateRangeFilterOptions.dateRangeOption)) {
      dateRangeStart = convertPredefinedDateRangeOptionToStartDateInSeconds(dateRangeFilterOptions.dateRangeOption);
    } else {
      if (dateRangeFilterOptions.dateRangeStart) {
        dateRangeStart = dateRangeFilterOptions.dateRangeStart;
      }

      if (dateRangeFilterOptions.dateRangeEnd) {
        dateRangeEnd = dateRangeFilterOptions.dateRangeEnd + 86399;
      }
    }
  }

  return {
    dateRangeStart,
    dateRangeEnd,
  };
}

export interface ReportListRequestBase {
  offset: number;
  pageSize: number;
}

export interface Pagination {
  count: number;
  offset: number;
  total: number;
}

export interface ReportListResponseBase {
  pagination: Pagination;
}

export interface UniversalResponse<T> {
  data: T[];
  pagination: Pagination;
}
