import {
  SortDirection,
  DateRangeFilterOptions,
  InvoiceReportStatus,
  InvoiceReportSortKey,
} from '@Libs/model';
import { TableColumnName, TableColumnProps, TableContext, TableSchema, TableSortOptions } from '@umw-admin/ui-kit';

export type InvoiceReportsTableColumnName = TableColumnName<
  'index' |
  'accountingInvoiceNumber' |
  'originOrderNumber' |
  'dateUtc' |
  'customerName' |
  'originSystemId' |
  'sumInclVat' |
  'dueDateUtc' |
  'statusType' |
  'details'>;

export type InvoiceReportsTableColumnProps = TableColumnProps<InvoiceReportsTableColumnName>;

export type InvoiceReportsTableSchema = TableSchema<InvoiceReportsTableColumnName>;

export type InvoiceReportsTableContext = TableContext<InvoiceReportsTableColumnName>;

export type InvoiceReportsFilterOptions = {
  salesChannels: string[] | null;
  searchKey: string | null;
  status: InvoiceReportStatus[] | null;
} & DateRangeFilterOptions;

export type InvoiceReportsSortOptions = TableSortOptions<InvoiceReportsTableColumnName>;

export type InvoiceReportsOptions = InvoiceReportsTableContext & {
  filter: InvoiceReportsFilterOptions;
};

export const INVOICE_REPORTS_TABLE_COLUMN_NAME_TO_SORT_KEY_MAP: Record<InvoiceReportsTableColumnName, InvoiceReportSortKey | null> = {
  'index': null,
  'accountingInvoiceNumber': InvoiceReportSortKey.ACCOUNTING_INVOICE_NUMBER,
  'originOrderNumber': null,
  'dateUtc': InvoiceReportSortKey.DATE_UTC,
  'customerName': InvoiceReportSortKey.CUSTOMER_NAME,
  'originSystemId': null,
  'sumInclVat': null,
  'dueDateUtc': null,
  'statusType': InvoiceReportSortKey.STATUS,
  'details': null,
};

export const DEFAULT_INVOICE_REPORTS_TABLE_CONTEXT: InvoiceReportsTableContext = {
  schema: [
    {
      name: 'index',
      width: 65,
      isVisible: true,
    },
    {
      name: 'accountingInvoiceNumber',
      width: 105,
      isVisible: true,
    },
    {
      name: 'originOrderNumber',
      width: 105,
      isVisible: true,
    },
    {
      name: 'dateUtc',
      width: 170,
      isVisible: true,
    },
    {
      name: 'customerName',
      width: 200,
      isVisible: true,
    },
    {
      name: 'originSystemId',
      width: 200,
      isVisible: true,
    },
    {
      name: 'sumInclVat',
      width: 145,
      isVisible: true,
    },
    {
      name: 'dueDateUtc',
      width: 105,
      isVisible: true,
    },
    {
      name: 'statusType',
      width: 165,
      isVisible: true,
    },
    {
      name: 'details',
      width: 110,
      isVisible: true,
    },
  ],
  sort: {
    direction: SortDirection.DESC,
    key: 'dateUtc',
  },
};
