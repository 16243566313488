export const environment = {
  name: 'prod',
  production: true,
  logging: {
    general: false,
    msal: false,
    ngxs: false,
    rollbar: true,
    routing: false
  },
  gateway: 'https://api.bridge.unimicro.no',
  billingService: {
    private: 'https://api.bridge.unimicro.no/private/billing',
    public: 'https://api.bridge.unimicro.no/public/billing',
    su: 'https://api.bridge.unimicro.no/su/billing',
  },
  signalRNotificationService: 'https://api.bridge.unimicro.no/SignalRNotificationService',
  suSignalRNotificationService: 'https://api.bridge.unimicro.no/su/SignalRNotificationService',
  companyManagerService: {
    private: 'https://api.bridge.unimicro.no/private/company',
    public: 'https://api.bridge.unimicro.no/public/company',
    anonymous: 'https://api.bridge.unimicro.no/anonymous/company',
    su: 'https://api.bridge.unimicro.no/su/company'
  },
  orderService: 'https://api.bridge.unimicro.no/order',
  payoutService: 'https://api.bridge.unimicro.no/payout',
  productSyncService: 'https://api.bridge.unimicro.no/product',
  reportingService: 'https://api.bridge.unimicro.no/reporting-service',
  unimicroPlatformService: 'https://api.bridge.unimicro.no/softrig',
  vippsService: 'https://api.bridge.unimicro.no/vipps',
  zettleService: 'https://api.bridge.unimicro.no/zettle',
  installShopifyAppUrl: 'https://apps.shopify.com/atlantic-ebridge',
  installMooCommerceAppUrl: 'https://moocommerce.no/unimicro-bridge',
  azure: {
    clientId: 'f80da88d-65a0-4975-9dec-97fa9033b496',
    authorityDomain: 'login.bridge.unimicro.no',
    userFlows: {
      signUpSignIn: {
        name: 'B2C_1A_SignUp_SignIn',
        authority: 'https://login.bridge.unimicro.no/b38783fa-4113-4a04-884c-7c9b6cc02174/B2C_1A_SignUp_Signin',
      },
      signUpSignInOnboarding: {
        name: 'B2C_1A_SignUp_SignIn_Onboarding',
        authority: 'https://login.bridge.unimicro.no/b38783fa-4113-4a04-884c-7c9b6cc02174/B2C_1A_SignUp_SignIn_Onboarding',
      },
      signUpSignInUniMicroAS: {
        name: 'B2C_1A_SignUp_SignIn_UniMicroAS',
        authority: 'https://login.bridge.unimicro.no/b38783fa-4113-4a04-884c-7c9b6cc02174/B2C_1A_SignUp_SignIn_UniMicroAS',
      },
    },
  },
  accountingSystems: {
    unimicro: {
      systemUrl: 'https://app.unimicro.no',
    },
    dnbRegnskap: {
      systemUrl: 'https://dnbregnskap.dnb.no',
    },
    srBankRegnskap: {
      systemUrl: 'https://regnskap.sparebank1.no',
    },
    eikaRegnskap: {
      systemUrl: 'https://system.eikaregnskap.no',
    },
    testUnimicro: {
      systemUrl: 'https://test.unimicro.no',
    },
  },
};
