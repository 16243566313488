import { DateRangeOption } from '@Libs/model';
import {
  DEFAULT_PAYOUT_SUMMARY_BOOKKEEPING_REPORTS_TABLE_CONTEXT,
  DEFAULT_PAYOUT_TRANSACTION_REPORTS_TABLE_CONTEXT,
  PayoutSummaryBookkeepingReportsOptions,
  PayoutTransactionReportsOptions,
} from '@WebUi/settlements/models/settlements.model';

export interface SettlementsStateModel {
  payoutTransaction: PayoutTransactionReportsOptions;
  payoutSummaryBookkeeping: PayoutSummaryBookkeepingReportsOptions;
}

export const SETTLEMENTS_STATE_DEFAULTS: SettlementsStateModel = {
  payoutTransaction: {
    ...DEFAULT_PAYOUT_TRANSACTION_REPORTS_TABLE_CONTEXT,
    filter: {
      dateRangeOption: DateRangeOption.CUSTOM_DATE_RANGE,
      dateRangeStart: null,
      dateRangeEnd: null,
      connectionId: [],
      paymentProvider: [],
      searchKey: '',
      status: [],
    },
  },
  payoutSummaryBookkeeping: {
    ...DEFAULT_PAYOUT_SUMMARY_BOOKKEEPING_REPORTS_TABLE_CONTEXT,
    filter: {
      dateRangeOption: DateRangeOption.CUSTOM_DATE_RANGE,
      dateRangeStart: null,
      dateRangeEnd: null,
      searchKey: '',
      status: [],
    },
  },
};
