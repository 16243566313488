export interface OrderDistributionConfiguration {
  id: number;
  orderDistributionPoints: OrderDistributionPoint[];
  distributeFromDateUnixTimeSeconds: number;
}

export interface NewOrderDistributionConfiguration {
  id: null;
  orderDistributionPoints: OrderDistributionPoint[];
  distributeFromDateUnixTimeSeconds: number;
}

export interface OrderDistributionPoint {
  connectionId: number;
  role: OrderDistributionPointRole;
}

export enum OrderDistributionPointRole {
  ORIGIN = 0,
  DESTINATION = 1,
}
