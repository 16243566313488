import { DateRangeOption, ProductOperationReportListRequest, ProductRecentReportListRequest } from '@Libs/model';

export const INITIAL_LOGS_AMOUNT = 50;

export interface RecentReportListOptions extends ProductRecentReportListRequest {
  dateRangeOption: DateRangeOption;
}

export interface OperationReportListOptions extends ProductOperationReportListRequest {
  dateRangeOption: DateRangeOption;
}
