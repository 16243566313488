import { ClearState } from '@WebUi/app/store/app.actions';
import { APP_STATE_DEFAULTS } from '@WebUi/app/models/app-state.model';
import { DASHBOARD_STATE_DEFAULTS } from '@WebUi/dashboard/models/dashboard-state.model';
import { INVOICES_STATE_DEFAULTS } from '@WebUi/invoices/models/invoices-state.model';
import { NOTIFICATIONS_STATE_DEFAULTS } from '@WebUi/notifications/models/notifications-state.model';
import { CONNECTIONS_STATE_DEFAULTS } from '@WebUi/connections/models/connections-state.model';
import { getActionTypeFromInstance, NgxsNextPluginFn } from '@ngxs/store';
import { ONBOARDING_STATE_DEFAULTS } from '@WebUi/onboarding/models/onboarding-state.model';
import { SU_STATE_DEFAULTS } from '@WebUi/su/models/su-state.model';
import { SETTLEMENTS_STATE_DEFAULTS } from '@WebUi/settlements/models/settlements-state.model';
import { PRODUCT_SYNC_STATE_DEFAULTS } from '@WebUi/product-sync/models/product-sync-state.model';
import { SETUP_WIZARD_STATE_DEFAULTS } from '@WebUi/setup-wizard/models/setup-wizard-state.model';
import { ORDERS_STATE_DEFAULTS } from '@WebUi/orders/models/orders-state.model';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function clearStatePlugin(state: any, action: any, next: NgxsNextPluginFn): any {
  const actionType = getActionTypeFromInstance(action);

  if (state && actionType === ClearState.type) {
    const newState: any = {
      app: {
        ...APP_STATE_DEFAULTS,
        lang: state.app?.lang ?? APP_STATE_DEFAULTS.lang,
      },
      connections: CONNECTIONS_STATE_DEFAULTS,
      dashboard: {
        ...DASHBOARD_STATE_DEFAULTS,
        dashboardSettings: {
          ...DASHBOARD_STATE_DEFAULTS.dashboardSettings,
          showDemoData: state.dashboard?.dashboardSettings?.showDemoData ?? DASHBOARD_STATE_DEFAULTS.dashboardSettings.showDemoData,
        },
      },
      invoices: {
        ...INVOICES_STATE_DEFAULTS,
        schema: INVOICES_STATE_DEFAULTS.schema ?? INVOICES_STATE_DEFAULTS.schema,
      },
      notifications: NOTIFICATIONS_STATE_DEFAULTS,
      onboarding: ONBOARDING_STATE_DEFAULTS,
      product_sync: PRODUCT_SYNC_STATE_DEFAULTS,
      settlements: SETTLEMENTS_STATE_DEFAULTS,
      setup_wizard: SETUP_WIZARD_STATE_DEFAULTS,
    };

    if (state.orders) {
      newState.orders = ORDERS_STATE_DEFAULTS;
    }

    if (state.su) {
      newState.su = SU_STATE_DEFAULTS;
    }

    return next(newState, action);
  }

  return next(state, action);
}
