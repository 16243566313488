import { ExternalSystem } from './external-system';
import { DateRange, ReportListRequestBase, ReportListResponseBase } from './report';

export interface OrderEventReport {
  createdAt: string;
  eventType: OrderEventType;
  externalOrderId: ExternalOrderId;
  id: number;
  originExternalOrderId: ExternalOrderId;
  status: OrderEventReportStatus;
  statusMessage: string | null;
  totalSumInclVat: number;
  correlationId: string | null;
  operationId: string | null;
  operationStartedBy: {
    externalSystemType: ExternalSystem;
    externalSystemId: string;
  } | null;
}

export interface ExternalOrderId {
  externalSystemType: ExternalSystem;
  externalSystemId: string;
  orderId: string;
}

export enum OrderEventType {
  ORDER_CREATED = 0,
  ORDER_UPDATED = 1,
  ORDER_FULFILLED = 2,
  ORDER_CANCELLED = 3,
  PAYMENT_COMPLETED = 4,
  PAYMENT_REFUNDED = 5,
}

export enum OrderEventReportStatus {
  SUCCESS = 0,
  WARNING = 1,
  FAILURE = 2,
}

export interface OrderEventReportsRequest extends ReportListRequestBase, DateRange {
  originOrderId: string | null;
}

export interface OrderEventReportsResponse extends ReportListResponseBase {
  orderEventReports: OrderEventReport[];
}
